<template>
  <div class="select-container" @click="handleClick">
    <el-select
      :value="value"
      :placeholder="placeholder"
      :popper-class="popperClass"
      :size="size"
      @change="handleChange"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>

    <Popup
      v-if="$store.getters.isSP"
      v-model="popupShow"
      position="bottom"
      :close-on-click-overlay="false"
      @click.stop="(ev) => ev.stopPropagation()"
    >
      <Picker
        show-toolbar
        :default-index="defaultIndex"
        value-key="label"
        :title="title"
        :confirm-button-text="$t('$.buttonOk2')"
        :cancel-button-text="$t('$.buttonCancel')"
        :columns="options"
        @confirm="handleChange"
        @cancel="popupShow = false"
      >
        <template #option="opt">
          <span :class="{ selected: opt.value === value }">
            {{ opt.label }}
          </span>
        </template>
      </Picker>
    </Popup>
  </div>
</template>

<script>
import Picker from "vant/lib/picker";
import Popup from "vant/lib/popup";
import "vant/lib/popup/style";
import "vant/lib/picker/style";

export default {
  components: { Popup, Picker },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [Number, String],
      require: true,
    },
    options: {
      type: Array,
      require: true,
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    popperClass: {
      type: String,
    },
    size: {
      type: String,
    },
  },
  data() {
    return {
      popupShow: false,
    };
  },
  computed: {
    defaultIndex() {
      return this.options.findIndex(({ value }) => value == this.value);
    },
  },
  methods: {
    handleClick() {
      this.popupShow = true;
    },
    handleChange(ev) {
      this.popupShow = false;
      this.$emit("change", ev?.value ?? ev);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  flex: 1;
}
.el-select {
  width: 100%;
}
.van-picker-column__item {
  .selected {
    color: $primary;
  }
}

@media screen and (max-width: $sp-width) {
  .select-container {
    position: relative;
    &::after {
      display: block;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
</style>
