import { expire2Str, getRsaCode } from "@/utils";
import $http from "./service";
import store from "@/store";
import { STORAGE_KEY } from "@/utils/constants";

export async function httpLogin(username, password) {
  const pw = getRsaCode(password);
  const res = await $http.post("user/login", {
    mail: username,
    password: pw,
    deviceInfo: "PC",
  });

  if (res.data.code === 200) {
    localStorage.setItem(STORAGE_KEY.MAIL, username);
    await store.dispatch("setToken", res.data.data.token);
  }

  return res.data;
}

export async function httpPwReset(mail, captcha, password) {
  const pw = getRsaCode(password);
  const res = await $http.put("user/resetPassword", {
    mail,
    captcha,
    password: pw,
  });
  return res.data;
}

export async function httpPwChange(password, newPassword) {
  const pw = getRsaCode(password);
  const newPw = getRsaCode(newPassword);
  const res = await $http.put("user/updatePassword", {
    sourcePassword: pw,
    targetPassword: newPw,
  });

  if (res.data.code === 200) {
    await store.dispatch("setToken", "");
  }

  return res.data;
}

export async function httpLogout() {
  const res = await $http.post("user/logout");
  if (res.data.code === 200) {
    await store.dispatch("setToken", "");
  }
  return res.data;
}

export async function httpDelAccount() {
  const res = await $http.delete("user/unsubscribe");
  if (res.data.code === 200) {
    await store.dispatch("setToken", "");
    localStorage.removeItem(STORAGE_KEY.MAIL);
  }
  return res.data;
}

export async function httpCheckMail(mail) {
  const res = await $http.get(`user/${mail}`);
  return res.data;
}

/**
 * 发送验证码
 * @param {string} mail
 * @param {1|2} type 类型（1：注册、2：重置密码）
 * @returns
 */
export async function httpSendCaptcha(mail, type) {
  const res = await $http.post(`user/captcha`, { mail, type });
  return res.data;
}

export async function httpCheckCaptcha(mail, captcha) {
  const params = { email: mail, captcha };
  const res = await $http.get(`user/checkCaptcha`, { params });
  return res.data;
}

export async function httpRegister(profile, password) {
  const pw = getRsaCode(password);
  const res = await $http.post("user/register", { ...profile, password: pw });
  return res.data;
}

export async function httpGetProfile() {
  const res = await $http.get("user");
  return res.data;
}

export async function httpUpdProfile(profile) {
  const res = await $http.put("user", profile);
  return res.data;
}

export async function httpGetCard() {
  const res = await $http.get("gmo/selectCard");
  return res.data;
}

export async function httpAddCard({ expireYear, expireMonth, ...params }) {
  const cardInfo = {
    ...params,
    expire: expire2Str(expireYear, expireMonth),
  };
  const res = await $http.post(
    "gmo/saveCard",
    { ...cardInfo },
    { timeout: 20 * 1000 }
  );
  return res.data;
}

export async function httpUpdCard({ expireYear, expireMonth, ...params }) {
  const cardInfo = {
    ...params,
    expire: expire2Str(expireYear, expireMonth),
  };
  const res = await $http.put(
    "gmo/updateCard",
    { ...cardInfo },
    { timeout: 20 * 1000 }
  );
  return res.data;
}

export async function httpDelCard() {
  const mail = localStorage.getItem(STORAGE_KEY.MAIL) || "guest";
  const res = await $http.delete(`gmo/deleteCard/${mail}`);
  return res.data;
}

export async function httpSendContact(params) {
  const res = await $http.post("common/createInquiry", params);
  return res.data;
}
