import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/i18n";
import store from "@/store";
import { LANG_LIST } from "@/utils/constants";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: () => import("@/views/WelcomeView.vue"),
  //   meta: {
  //     showAside: false,
  //     requireAuth: false,
  //   },
  // },
  {
    path: "/welcome",
    alias: "/",
    name: "welcome",
    component: () => import("@/views/WelcomeView.vue"),
    props: (route) => ({ block: route.params.block }),
    meta: {
      /** 是否显示左边 */
      showAside: false,
      /** 是否登录鉴权  -- true: 必须登录, false: 必须未登录, undefined: 不鉴权 */
      requireAuth: false,
      /** 标题的i18n的key */
      // titleI18n: "account.pageTitle",
      /** 显示loading，默认显示，不显示需要设定false */
      loading: true,
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogin) {
        next({ name: "account", params: { block: true } });
        return;
      }
      next();
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    props: (route) => ({ block: route.params.block }),
    meta: {
      requireAuth: false,
      showAside: false,
      titleI18n: "login.drawer.title",
      loading: false,
    },
  },
  {
    path: "/pw/reset",
    name: "pwReset",
    component: () => import("../views/PwResetView.vue"),
    meta: {
      requireAuth: false,
      titleI18n: "password.resetPageTitle",
    },
  },
  {
    path: "/pw/change",
    name: "pwChange",
    component: () => import("../views/PwChangeView.vue"),
    meta: {
      requireAuth: true,
      showAside: true,
      titleI18n: "password.changePageTitle",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
    meta: {
      requireAuth: false,
      titleI18n: "register.mailInput.pageTitle",
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/views/AccountInfoView.vue"),
    props: (route) => ({ block: route.params.block }),
    meta: {
      requireAuth: true,
      showAside: true,
      titleI18n: "account.pageTitle",
    },
  },
  {
    path: "/profile/update",
    name: "profileUpdate",
    component: () => import("@/views/ProfileEditView.vue"),
    meta: {
      requireAuth: true,
      showAside: true,
      titleI18n: "profile.pageTitleProfile",
    },
  },
  {
    path: "/payment/add",
    name: "paymentAdd",
    component: () => import("@/views/PaymentEditView.vue"),
    meta: {
      requireAuth: true,
      showAside: true,
      titleI18n: "payment.pageTitleAdd",
    },
  },
  {
    path: "/payment/update",
    name: "paymentUpdate",
    component: () => import("@/views/PaymentEditView.vue"),
    meta: {
      requireAuth: true,
      showAside: true,
      titleI18n: "payment.pageTitleEdit",
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/PdfView.vue"),
    meta: {
      showAside: true,
      titleI18n: "statute.pageTitle",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/PdfView.vue"),
    meta: {
      showAside: true,
      titleI18n: "privacyPolicy.pageTitle",
    },
  },
  {
    path: "/tokusyo",
    name: "tokusyo",
    component: () => import("@/views/PdfView.vue"),
    meta: {
      showAside: true,
      titleI18n: "settings.itemTokusyo",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/ContactView.vue"),
    meta: {
      showAside: true,
      titleI18n: "contactUs.pageTitle",
    },
  },
  {
    path: "/faq",
    name: "questions",
    component: () => import("@/views/QuestionsView.vue"),
    meta: {
      showAside: true,
      titleI18n: "questions.pageTitle",
    },
  },
  {
    path: "/404",
    name: "notFound",
    component: () => import("@/views/NotFoundView.vue"),
    meta: {
      titleI18n: "404.page-title",
    },
  },
  {
    path: "*",
    redirect: { name: "notFound" },
  },
];

function createRouter() {
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });
  return router;
}
const router = createRouter();

router.beforeEach(async (to, from, next) => {
  console.log("=== beforeEach:", from.name, "->", to.name);
  // console.log(to);
  Vue.prototype.$loading(to.meta.loading !== false, to.meta.showAside);

  // console.log("requireAuth:", to.meta.requireAuth);
  // console.log("isLogin:", store.getters.isLogin);

  if (to.meta.requireAuth === true) {
    if (!store.getters.isLogin) {
      next({ name: "login", replace: true, params: { block: true } });
      return;
    }
  } else if (to.meta.requireAuth === false) {
    if (store.getters.isLogin) {
      next({ name: "account", replace: true, params: { block: true } });
      return;
    }
  }

  next();
});

router.afterEach((to) => {
  window.scrollTo(0, 0);

  const lang = to.query.lang;
  if (
    lang &&
    lang !== i18n.locale &&
    LANG_LIST.some(({ langCode }) => langCode == lang)
  ) {
    i18n.locale = lang;
  }

  if (to.meta.titleI18n) {
    document.title = `${i18n.t(to.meta.titleI18n)} - COLEX`;
  } else {
    document.title = "COLEX";
  }
});

export default router;
