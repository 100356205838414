import Vue from "vue";
import i18n from "./i18n";
import "./http/service";
import store from "./store";
import router from "./router";
import App from "./App.vue";
import {
  Aside,
  Button,
  Checkbox,
  Collapse,
  CollapseItem,
  Container,
  DatePicker,
  Divider,
  Drawer,
  Footer,
  Form,
  FormItem,
  Header,
  Input,
  Link,
  Loading,
  Main,
  Menu,
  MenuItem,
  MessageBox,
  Option,
  Select,
  Submenu,
} from "element-ui";
import ElementLocale from "element-ui/lib/locale";
import "./assets/styles/index.scss";
import FormLabelCom from "./components/FormLabelCom.vue";
import RequiredIconCom from "./components/RequiredIconCom.vue";
import SelectCom from "./components/SelectCom.vue";
import DatePickerCom from "./components/DatePickerCom.vue";
import globalMixin from "./components/mixins/globalMixin";

Vue.config.productionTip = false;

// Vue.use(ElementUI);
ElementLocale.i18n((key, value) => i18n.t(key, value));
Vue.component(Button.name, Button);
Vue.component(Container.name, Container);
Vue.component(Aside.name, Aside);
Vue.component(Main.name, Main);
Vue.component(Header.name, Header);
Vue.component(Footer.name, Footer);
Vue.component(Input.name, Input);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Menu.name, Menu);
Vue.component(Submenu.name, Submenu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Drawer.name, Drawer);
Vue.component(Link.name, Link);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Divider.name, Divider);

Vue.component("g-form-label", FormLabelCom);
Vue.component("g-required-icon", RequiredIconCom);
Vue.component("g-select", SelectCom);
Vue.component("g-date-picker", DatePickerCom);

Vue.prototype.$msgbox = MessageBox;

Vue.prototype.$alert = (message, title, options = {}) => {
  let _title = i18n.t("$.messageTitle");
  let _options = { ...options };
  if (typeof title === "string") {
    _title = title;
  } else if (typeof title === "object") {
    _options = Object.assign(title, options);
  }

  _options = Object.assign(
    {
      center: true,
      showClose: false,
      confirmButtonText: i18n.t("$.buttonClose"),
    },
    _options
  );

  return MessageBox.alert(message, _title, _options);
};

Vue.prototype.$confirm = (message, title, options = {}) => {
  let _title = i18n.t("$.messageTitle");
  let _options = { ...options };
  if (typeof title === "string") {
    _title = title;
  } else if (typeof title === "object") {
    _options = Object.assign(title, options);
  }

  _options = Object.assign(
    {
      center: true,
      showClose: false,
      closeOnClickModal: false,
      confirmButtonText: i18n.t("$.buttonOk"),
      cancelButtonText: i18n.t("$.buttonCancel"),
    },
    _options
  );

  return MessageBox.confirm(message, _title, _options);
};

Vue.prototype.$loading = (loading = true, havAside) => {
  if (loading) {
    Vue.prototype._loading = Loading.service({
      customClass: havAside ? "hav-aside" : "",
    });
  } else {
    Vue.prototype._loading?.close();
  }
};

Vue.mixin(globalMixin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

if (process.env.NODE_ENV === "development" && /dev=1/i.test(location.search)) {
  import("eruda").then((eruda) => eruda.default.init());
}
