<template>
  <el-drawer
    class="drawer"
    :visible.sync="$store.getters.navDrawer"
    :show-close="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :wrapperClosable="false"
    size="100%"
  >
    <template #title>
      <PageHeaderCom :menuClose="true" />
    </template>
    <div class="scroll">
      <NavCom @select="handleNavSelect" />
      <StoreCom :nav="true" />
    </div>
  </el-drawer>
</template>

<script>
import NavCom from "./NavCom.vue";
import StoreCom from "./StoreCom.vue";
import PageHeaderCom from "./PageHeaderCom.vue";

export default {
  components: { NavCom, StoreCom, PageHeaderCom },
  methods: {
    handleNavSelect() {
      this.$store.dispatch("setNavDrawer", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  ::v-deep .el-drawer__header {
    height: $header-height;
    padding: 0;
    margin: 0;
    .item {
      display: block;
    }
  }
}
.scroll {
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
