import Vue from "vue";
import Vuex from "vuex";
import global from "@/assets/styles/global.module.scss";
import { STORAGE_KEY } from "@/utils/constants";
import { httpGetCard, httpGetProfile } from "@/http/httpApi";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /** 页面的宽度 */
    _pageWidth: window.innerWidth,
    /** 是否显示 Nav 的抽屉 */
    _navDrawerVisible: false,
    /** 登录的token */
    _token: localStorage.getItem(STORAGE_KEY.TOKEN),
    /** 绑卡 (undefined: 未加载, null: 未绑卡, obj:绑卡数据) */
    _bindCard: undefined,
    /** 用户信息 */
    _profile: undefined,
  },
  getters: {
    /** 是否显示手机界面 */
    isSP(state) {
      return state._pageWidth <= parseInt(global.spWidth);
    },
    /** 是否显示PC界面 */
    isPC(state) {
      return state._pageWidth > parseInt(global.spWidth);
    },
    /** 是否显示导航抽屉（sp） */
    navDrawer(state) {
      return state._navDrawerVisible;
    },
    /** 是否登录 */
    isLogin(state) {
      return !!state._token;
    },
    /** 是否绑卡 */
    isBindCard(state) {
      return !!state._bindCard;
    },
    /** 是否加载了绑卡 */
    isBindCardLoad(state) {
      return state._bindCard !== undefined;
    },
    profile(state) {
      return state._profile || {};
    },
  },
  mutations: {
    setPageWidth: (state, width) => {
      return (state._pageWidth = width);
    },
    setNavDrawer: (state, visible) => {
      return (state._navDrawerVisible = visible);
    },
    setToken: (state, token) => {
      localStorage.setItem(STORAGE_KEY.TOKEN, token);
      return (state._token = token);
    },
    setBindCard(state, card) {
      return (state._bindCard = card);
    },
    setProfile(state, card) {
      return (state._profile = card);
    },
  },
  actions: {
    setPageWidth({ commit }) {
      commit("setPageWidth", window.innerWidth);
    },
    setNavDrawer({ commit }, visible) {
      commit("setNavDrawer", visible);
    },
    setToken({ commit, dispatch }, token) {
      commit("setToken", token);

      dispatch("reloadBindCard");
    },
    setBindCard({ commit }, card) {
      commit("setBindCard", card);
    },
    loadProfile({ commit }) {
      if (this._profilePromise) return this._profilePromise;
      this._profilePromise = httpGetProfile()
        .then((res) => {
          if (res.code === 200) {
            commit("setProfile", res.data);
          }
          return res;
        })
        .finally(() => {
          this._profilePromise = null;
        });
      return this._profilePromise;
    },
    loadBindCard({ commit, getters, dispatch }) {
      if (
        !getters.isBindCardLoad &&
        !this._isBindCardLoading &&
        getters.isLogin
      ) {
        this._isBindCardLoading = true;
        httpGetCard()
          .then((resCard) => {
            if (resCard.code === 200) {
              commit("setBindCard", resCard.data);
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this._isBindCardLoading = false;
          });

        dispatch("loadProfile");
      }
    },
    reloadBindCard({ commit, dispatch }) {
      commit("setBindCard", undefined);
      dispatch("loadBindCard");
    },
  },
  modules: {},
});
