<template>
  <el-menu
    class="menu-container"
    ref="menu"
    :default-active="navActive"
    :default-openeds="['accountGroup', 'otherGroup']"
    @close="handleNavClose"
    @select="handleNavSelect"
  >
    <el-menu-item
      v-show="$store.getters.isLogin"
      index="account"
      route="/account"
    >
      <span>{{ $t("account.pageTitle") }}</span>
    </el-menu-item>
    <el-submenu index="accountGroup">
      <template slot="title">
        <span>{{ $t("settings.itemAccountSettings") }}</span>
      </template>
      <el-menu-item
        v-show="$store.getters.isLogin"
        index="profileUpdate"
        route="/profile/update"
      >
        <span>{{ $t("settings.itemProfile") }}</span>
      </el-menu-item>
      <el-menu-item
        v-show="$store.getters.isLogin"
        index="pwChange"
        route="/pw/change"
      >
        <span>{{ $t("settings.itemPasswordChange") }}</span>
      </el-menu-item>
      <el-menu-item
        v-show="
          $store.getters.isLogin &&
          $store.getters.isBindCardLoad &&
          !$store.getters.isBindCard
        "
        index="paymentAdd"
        route="/payment/add"
      >
        <span>{{ $t("settings.itemPaymentAdd") }}</span>
      </el-menu-item>
      <el-menu-item
        v-show="
          $store.getters.isLogin &&
          $store.getters.isBindCardLoad &&
          $store.getters.isBindCard
        "
        index="paymentUpdate"
        route="/payment/update"
      >
        <span>{{ $t("settings.itemPaymentEdit") }}</span>
      </el-menu-item>
      <el-menu-item v-show="$store.getters.isLogin" index="logout" class="mini">
        <span>{{ $t("settings.itemLogout") }}</span>
      </el-menu-item>
      <el-menu-item
        v-show="
          $store.getters.isLogin &&
          $store.getters.isBindCardLoad &&
          $store.getters.isBindCard
        "
        index="termination"
        class="mini"
      >
        <span>{{ $t("settings.itemTermination") }}</span>
      </el-menu-item>
      <el-menu-item
        v-show="$store.getters.isLogin"
        index="accountDel"
        class="mini last"
      >
        <span>{{ $t("settings.itemAccountDel") }}</span>
      </el-menu-item>
      <el-menu-item
        v-show="!$store.getters.isLogin"
        index="login"
        route="/login"
      >
        <span>{{ $t("settings.itemLogin") }}</span>
      </el-menu-item>
      <el-menu-item
        v-show="!$store.getters.isLogin"
        index="register"
        route="/register"
      >
        <span>{{ $t("settings.itemRegister") }}</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="otherGroup">
      <template slot="title">
        <span>{{ $t("settings.groupOther") }}</span>
      </template>
      <el-menu-item index="terms" route="/terms" class="no-arrow">
        <span>{{ $t("settings.itemStatute") }}</span>
        <img class="icon" src="@/assets/images/nav-file-icon.svg" alt="icon" />
      </el-menu-item>
      <el-menu-item index="privacy" route="/privacy" class="no-arrow">
        <span>{{ $t("settings.itemPrivacyPolicy") }}</span>
        <img class="icon" src="@/assets/images/nav-file-icon.svg" alt="icon" />
      </el-menu-item>
      <el-menu-item index="tokusyo" route="/tokusyo" class="no-arrow">
        <span>{{ $t("settings.itemTokusyo") }}</span>
        <img class="icon" src="@/assets/images/nav-file-icon.svg" alt="icon" />
      </el-menu-item>
      <el-menu-item index="questions" route="/faq">
        <span>{{ $t("settings.itemQuestions") }}</span>
      </el-menu-item>
      <el-menu-item index="contact" route="/contact">
        <span>{{ $t("settings.itemContactUs") }}</span>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
import global from "@/assets/styles/global.module.scss";
import { httpDelAccount, httpDelCard, httpLogout } from "@/http/httpApi";
import { dateFormat } from "@/utils";
export default {
  data() {
    return {
      global,
    };
  },
  computed: {
    navActive() {
      return this.$route.name;
    },
  },
  created() {
    this.$store.dispatch("loadBindCard");
  },
  methods: {
    handleNavClose(index) {
      this.$refs["menu"].open(index);
    },
    handleNavSelect(index, indexPath, { route }) {
      if (this.debounce()) {
        if (route) {
          if (this.$route.path !== route) {
            this.$router.push(route);
            this.$emit("select", index);
          }
        } else {
          switch (index) {
            case "logout":
              this.logoutConfirm();
              break;
            case "termination":
              this.terminationConfirm();
              break;
            case "accountDel":
              this.accountDelConfirm();
              break;
            default:
              break;
          }
          this.$nextTick(() => {
            this.$refs["menu"].activeIndex = this.$route.name;
          });
        }
      }
    },

    logoutConfirm() {
      this.$confirm(
        this.$t("settings.msgLogout"),
        this.$t("settings.itemLogout")
      )
        .then(async () => {
          if (await this.logout()) {
            this.$router.replace({ name: "welcome", params: { block: true } });
            this.$emit("select");
          }
        })
        .catch(() => {});
    },
    accountDelConfirm() {
      const [msg1, msg2] = this.$t("settings.msgAccountDel").split(/\n\n/);
      this.$confirm(
        `<p class="left">${msg1}</p><span>${msg2}</span>`,
        this.$t("settings.msgAccountDelTitle"),
        {
          customClass: "account-del-msg",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(async () => {
          if (await this.accountDel()) {
            this.$router.replace({ name: "welcome", params: { block: true } });
            this.$emit("select");
          }
        })
        .catch(() => {});
    },
    terminationConfirm() {
      const mail = this.$store.getters.profile?.mail || "";
      const end = dateFormat(this.$store.getters.profile?.endTime);
      const expirationDateLabel = this.$t("terminate.expirationDateLabel");
      const expirationDate = `${expirationDateLabel}：${end}`;
      const msg1 = `<span>${mail}\n${expirationDate}</span>`;
      const msg2 = `<p class="left">${this.$t("terminate.tip")}</p>`;

      this.$confirm(`${msg1}${msg2}`, this.$t("terminate.msgTerminateTitle"), {
        confirmButtonText: this.$t("terminate.btn"),
        customClass: "terminate-msg",
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          if (await this.termination()) {
            this.$store.dispatch("reloadBindCard");
            this.$emit("select");
          }
        })
        .catch(() => {});
    },

    async logout() {
      const res = await httpLogout();
      if (res.code === 200) {
        return true;
      }
      return false;
    },
    async termination() {
      const res = await httpDelCard();
      if (res.code === 200) {
        this.$alert(
          this.$t("terminate.msgTerminateSucceed"),
          this.$t("terminate.msgTerminateSucceedTitle")
        );
        return true;
      }
      return false;
    },
    async accountDel() {
      const res = await httpDelAccount();
      if (res.code === 200) {
        return true;
      }
      return false;
    },

    debounce() {
      if (this._debounceFlag) {
        return false;
      }
      this._debounceFlag = true;
      setTimeout(() => {
        this._debounceFlag = false;
      }, 100);
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin nav-item-default {
  color: $tx-color;
  background-color: transparent;
  &::before {
    display: none;
  }
}
@mixin nav-item-active {
  color: $-tx-color;
  background-color: transparent;

  &::before {
    content: "";
    display: block;
    background-color: $-bg-color;
    border-radius: 5px 0 0 5px;
    width: calc(100% - 6px);
    height: 36px;
    position: absolute;
    right: 0;
    top: 7px;
  }

  &.mini::before {
    height: 30px;
    top: 5px;
  }
}

.menu-container {
  &,
  ::v-deep .el-menu {
    background-color: transparent;
    border: none;
  }

  ::v-deep .el-submenu__title {
    color: $tx-color;
    font-size: 15px;
    background-color: rgba(86, 91, 118, 1);
    > .el-submenu__icon-arrow {
      display: none;
    }
  }

  .el-submenu {
    ::v-deep .el-menu {
      // 控制缩进
      margin-left: 40px;
    }
  }

  .el-menu-item {
    @include nav-item-default;
    position: relative;
    padding: 0 35px 0 20px !important;

    height: 50px;
    font-size: 13px;
    line-height: 18px;

    white-space: inherit;
    display: flex;
    align-items: center;

    &.el-menu-item.last,
    &.el-menu-item:not(:last-of-type) {
      box-sizing: content-box;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
    > span {
      position: relative;
      vertical-align: baseline;
    }
    // 右箭头
    &::after {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      border-right: 1px solid $tx-color;
      border-top: 1px solid $tx-color;
      transform: rotate(45deg);
      position: absolute;
      right: 22px;
      top: 22px;
    }
    &.no-arrow::after {
      display: none;
    }
    // 自定义的icon
    > .icon {
      position: absolute;
      right: 17px;
      top: 20px;
    }

    &:not(.is-active):focus {
      @include nav-item-default;
    }
    &:not(.is-active):hover {
      @include nav-item-active;
    }
    // 选中样式
    &.is-active {
      @include nav-item-active;
    }

    &.mini {
      height: 40px;
      font-size: 11px;

      &::after {
        top: 17px;
      }
    }
  }
}

@media screen and (max-width: $sp-width) {
  .menu-container {
    .el-menu-item {
      &:not(.is-active):hover {
        @include nav-item-default;
      }
    }
  }
}
</style>

<style lang="scss">
.account-del-msg {
  .el-message-box__message {
    .left {
      text-align: left;
      margin-bottom: 44px;
    }
  }
}
.terminate-msg {
  .el-message-box__message {
    .left {
      text-align: left;
      margin-top: 16px;
      margin-bottom: 44px;
    }
  }
}
</style>
