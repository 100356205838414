<template>
  <el-container id="app" :class="[$route.name, $route.query.model]">
    <el-header class="header" :style="{ height: headerHeight }">
      <PageHeader />
    </el-header>

    <el-aside
      v-if="$store.getters.isPC && $route?.meta?.showAside"
      class="aside"
      :style="{ width: asideWidth }"
    >
      <PageAside />
    </el-aside>

    <el-container class="right">
      <el-main class="main" style="padding: 0">
        <div>
          <PageTitle class="title" />
          <router-view :key="$route.name" />
        </div>
      </el-main>

      <el-footer class="footer" style="padding: 0; height: auto">
        <PageFooter />
      </el-footer>
    </el-container>

    <NavDrawer />
    <CookiesDrawer v-if="cookiesDrawerVisible" />
  </el-container>
</template>

<script>
import PageHeader from "@/components/PageHeaderCom.vue";
import PageAside from "@/components/PageAsideCom.vue";
import PageFooter from "@/components/PageFooterCom.vue";
import PageTitle from "@/components/PageTitleCom.vue";
import NavDrawer from "@/components/NavDrawerCom.vue";
import CookiesDrawer from "./components/CookiesDrawerCom.vue";
import global from "@/assets/styles/global.module.scss";

export default {
  components: {
    PageHeader,
    PageAside,
    PageFooter,
    PageTitle,
    NavDrawer,
    CookiesDrawer,
  },
  data() {
    return {
      headerHeight: global.headerHeight,
      asideWidth: global.asideWidth,
    };
  },
  computed: {
    cookiesDrawerVisible() {
      return !(
        this.$route.query.model === "app" &&
        ["questions", "contact"].includes(this.$route.name)
      );
    },
  },
  mounted() {
    window.addEventListener("resize", this.windowResize, false);
  },
  methods: {
    windowResize() {
      this.$store.dispatch("setPageWidth");
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;

  .aside {
    height: calc(100% - $header-height);
    position: fixed;
    left: 0;
    top: $header-height;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    overflow: hidden;
  }

  .right {
    padding-top: $header-height;
  }
  .aside + .right {
    padding-left: $aside-width;
  }

  .header {
    width: 100%;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
  }

  .main {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    > div {
      max-width: 1065px;
      width: 100%;
      padding: 0 70px 50px;
    }
  }

  .footer {
    width: 100%;
  }
}

@media screen and (max-width: $sp-width) {
  #app {
    .main {
      > div {
        padding: 0 25px 50px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: $sp-width) {
  // app 的 webview 打开时
  #app.questions.app,
  #app.contact.app {
    .header,
    .title,
    .coolies-drawer {
      display: none;
    }

    .right {
      padding-top: 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
/* 设置welcome的背景 */
@media screen and not (max-width: $sp-width) {
  #app.welcome {
    background-image: url(@/assets/images/welcome-bg.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .header-container {
      background-color: transparent;
    }
  }
}

@media screen and (max-width: $sp-width) {
  #app.welcome {
    background-image: url(@/assets/images/welcome-bg-sp.jpg);
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    .header-container {
      background-color: transparent;
    }
  }
}
</style>
