<template>
  <div @click="handleClick">
    <el-date-picker
      type="date"
      :value="birthday"
      :placeholder="placeholder"
      :readonly="$store.getters.isSP"
      :editable="false"
      :clearable="false"
      @input="handleChange"
    />

    <Popup
      v-if="$store.getters.isSP"
      v-model="popupShow"
      position="bottom"
      :close-on-click-overlay="false"
      @click="(ev) => ev.stopPropagation()"
    >
      <DatetimePicker
        type="date"
        :value="birthdayDate"
        :title="title"
        :max-date="maxDate"
        :min-date="minDate"
        :confirm-button-text="$t('$.buttonOk2')"
        :cancel-button-text="$t('$.buttonCancel')"
        :formatter="(type, value) => `${type}-${value}`"
        @confirm="handleChange"
        @cancel="popupShow = false"
      >
        <template #option="opt">
          <span :class="{ selected: isSelected(opt) }">
            {{ opt?.split("-")[1] || opt }}
          </span>
        </template>
      </DatetimePicker>
    </Popup>
  </div>
</template>

<script>
import DatetimePicker from "vant/lib/datetime-picker";
import Popup from "vant/lib/popup";
import "vant/lib/datetime-picker/style";
import "vant/lib/popup/style";
import { date2str } from "@/utils";

export default {
  components: { Popup, DatetimePicker },
  model: {
    prop: "birthday",
    event: "change",
  },
  props: {
    birthday: {
      type: String,
      require: true,
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      popupShow: false,
      maxDate: new Date(),
      minDate: new Date(1900, 0, 1),
    };
  },
  computed: {
    birthdayDate() {
      return this.birthday ? new Date(this.birthday) : new Date();
    },
    strYear() {
      return ("0000" + this.birthdayDate.getFullYear()).slice(-4);
    },
    strMonth() {
      return ("00" + (this.birthdayDate.getMonth() + 1)).slice(-2);
    },
    strDay() {
      return ("00" + this.birthdayDate.getDate()).slice(-2);
    },
  },
  methods: {
    isSelected(opt) {
      const [type, value] = opt.split("-");
      if (type === "year") {
        return value === this.strYear;
      }
      if (type === "month") {
        return value === this.strMonth;
      }
      if (type === "day") {
        return value === this.strDay;
      }
      return false;
    },
    handleClick() {
      this.popupShow = true;
    },
    handleChange(ev) {
      this.popupShow = false;
      this.$emit("change", date2str(ev));
    },
  },
};
</script>

<style lang="scss" scoped>
.el-date-editor {
  width: 100%;
}

.van-picker-column__item {
  .selected {
    color: $primary;
  }
}
</style>
