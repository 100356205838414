<template>
  <div class="store-container" :class="{ nav: nav }">
    <div class="app-store">
      <img
        class="link"
        @click="handleAppStoreClick"
        src="../assets/images/app-store.svg"
        alt="AppStore"
      />
      <img
        class="qr"
        src="../assets/images/qr-colex-app-store.png"
        alt="app-store"
      />
    </div>
    <div class="google-play">
      <img
        class="link"
        @click="handleGooglePlayClick"
        src="../assets/images/google-play.svg"
        alt="GooglePlay"
      />
      <img
        class="qr"
        src="../assets/images/qr-colex-google-play.png"
        alt="google-play"
      />
    </div>
  </div>
</template>

<script>
import { APP_STORE_URL, GOOGLE_PLAY_URL } from "@/utils/constants";

export default {
  props: {
    nav: { type: Boolean },
  },
  data() {
    return {};
  },
  methods: {
    handleAppStoreClick() {
      window.open(APP_STORE_URL);
    },
    handleGooglePlayClick() {
      window.open(GOOGLE_PLAY_URL);
    },
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.store-container {
  width: 520px;
  display: flex;
  justify-content: space-between;
  > div {
    width: 250px;
    display: flex;
    justify-content: center;
  }

  .qr {
    width: 53px;
    height: 53px;
    margin-left: 15px;
  }
}
.nav.store-container {
  width: 100%;
  justify-content: space-around;
  padding-bottom: 30px;
  padding-top: 20px;
  > div {
    width: auto;
    flex-direction: column;
    align-items: center;

    .qr {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}
@media screen and (max-width: $sp-width) {
  .store-container {
    width: 100%;
    justify-content: center;
    > div {
      width: auto;
      &.app-store {
        justify-content: flex-end;
        margin-right: 10px;
      }
      &.google-play {
        justify-content: flex-start;
        margin-left: 10px;
      }
    }
    .qr {
      display: none;
    }
  }
}
</style>
