<template>
  <div class="header-container">
    <div
      class="logo"
      :class="{ aside: $route?.meta?.showAside && $store.getters.isPC }"
      v-if="logoVisible"
      @click="handleClickLogo"
    >
      <img src="../assets/logo.svg" alt="COLEX" />
    </div>
    <div class="right">
      <!-- <img class="item" src="@/assets/images/colex-X.svg" alt="X" /> -->
      <!-- <img class="item" src="@/assets/images/colex-ig.svg" alt="ig" /> -->
      <LanguageSelectCom class="item" />
      <img
        v-if="!menuClose"
        class="item menu"
        @click="() => handleClickMenu(true)"
        src="@/assets/images/menu-sp-icon.svg"
        alt="MENU"
      />
      <img
        v-else
        class="item menu"
        @click="() => handleClickMenu(false)"
        src="@/assets/images/menu-close-sp-icon.svg"
        alt="CLOSE"
      />
    </div>
  </div>
</template>

<script>
import LanguageSelectCom from "./LanguageSelectCom.vue";

export default {
  components: { LanguageSelectCom },
  props: {
    menuClose: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {};
  },
  computed: {
    logoVisible() {
      const notWelcome =
        this.$route.name && !["welcome", "notFound"].includes(this.$route.name);
      const spVisible = this.$store.getters.isSP ? !this.menuClose : true;
      return notWelcome && spVisible;
    },
  },
  methods: {
    handleClickMenu(visible) {
      this.$store.dispatch("setNavDrawer", visible);
    },
    handleClickLogo() {
      if (this.$store.getters.isLogin) {
        if (this.$route.name !== "account") {
          this.$router.push({ name: "account" });
        }
      } else {
        if (this.$route.name !== "welcome") {
          this.$router.push({ name: "welcome" });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  width: 100%;
  height: 100%;
  background-color: $bg-color;

  display: flex;

  .logo {
    height: $header-height;
    display: flex;
    align-items: center;
    cursor: pointer;
    > img {
      margin-left: 25px;
      height: 23px;
    }

    &.aside {
      width: $aside-width;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .item {
    margin-right: 20px;
    cursor: pointer;
    &.menu {
      display: none;
    }
  }
}

@media screen and (max-width: $sp-width) {
  .header-container {
    .item {
      display: none;
      &.menu {
        display: block;
        margin-top: 6px;
      }
    }
  }
}
</style>
