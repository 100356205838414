<template>
  <el-drawer
    :visible.sync="cookiesShow"
    :show-close="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :append-to-body="true"
    :wrapperClosable="false"
    :with-header="false"
    size="100%"
    direction="btt"
    style="background-color: transparent"
  >
    <div class="cookies-container">
      <p class="desc">{{ $t("cookies.cookiesDesc") }}</p>
      <el-button @click="() => handleBtnClick(false)">
        {{ $t("cookies.btnDisagree") }}
      </el-button>
      <el-button type="primary" @click="() => handleBtnClick(true)">
        {{ $t("cookies.btnAgree") }}
      </el-button>
    </div>
  </el-drawer>
</template>

<script>
import { COOKIES_AGREE_KEY } from "@/utils/constants";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      cookiesShow: false,
    };
  },
  mounted() {
    this._timer = setTimeout(() => {
      const agree = Cookies.get(COOKIES_AGREE_KEY);
      this.cookiesShow = !agree;
    }, 1000);
  },
  destroyed() {
    clearTimeout(this._timer);
  },
  methods: {
    handleBtnClick(agree) {
      clearTimeout(this._timer);
      this.cookiesShow = false;
      if (agree) {
        Cookies.set(COOKIES_AGREE_KEY, 1, { expires: 365 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer {
  background-color: transparent;
}
.cookies-container {
  background-color: $-bg-color;
  color: $-tx-color;
  height: 100px;
  width: 100%;
  padding: 0 35px;
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;

  .desc {
    flex: 1;
    font-size: 13px;
    line-height: 26px;
  }
  .el-button {
    width: 163px;
    margin-left: 31px;
    padding: 6.5px 0px;
  }
}

@media screen and (max-width: $sp-width) {
  .cookies-container {
    flex-direction: column;
    height: auto;
    padding: 30px;

    position: absolute;
    bottom: 0;
    .el-button {
      width: 100%;
      margin-left: 0;
      margin-top: 15px;
      padding: 16.5px 0px;
    }
  }
}
</style>
