import Vue from "vue";
import VueI18n from "vue-i18n";
import { LANG_LIST, STORAGE_KEY } from "./utils/constants";
import ja from "@/assets/langs/ja.json";
import en from "@/assets/langs/en.json";
import elementJa from "element-ui/lib/locale/lang/ja";
import elementEn from "element-ui/lib/locale/lang/en";

Vue.use(VueI18n);

// function loadLocaleMessages() {
//   const locales = require.context(
//     "./assets/langs",
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );
//   const messages = {};
//   locales.keys().forEach((key) => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });
//   return messages;
// }

const setLang = localStorage.getItem(STORAGE_KEY.LANG);
const lang = (() => {
  const _lang = navigator.language.replace("-", "_").toLocaleLowerCase();
  if (LANG_LIST.some((item) => item.langCode.toLocaleLowerCase() === _lang)) {
    return _lang;
  }
  return null;
})();

export default new VueI18n({
  locale: setLang || lang || "en",
  fallbackLocale: "en",
  // messages: loadLocaleMessages(),
  messages: {
    ja: {
      ...ja,
      ...elementJa,
    },
    en: {
      ...en,
      ...elementEn,
    },
  },
});
