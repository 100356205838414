"use strict";

import Vue from "vue";
import axios from "axios";
import { STORAGE_KEY } from "@/utils/constants";
import i18n from "@/i18n";
import { MessageBox } from "element-ui";
import router from "@/router";
import store from "@/store";

const config = {
  baseURL: "/video-service/api/v1/app",
  timeout: 60 * 1000, // Timeout
};

const service = axios.create(config);

service.interceptors.request.use(
  function (config) {
    console.log(
      `%crequest [${config.method}]${config.url}`,
      "color: #0000ff;",
      config
    );

    if (["get", "delete"].includes(config.method.toLowerCase())) {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded;";
    } else {
      config.headers["Content-Type"] = "application/json;";
    }

    const token = localStorage.getItem(STORAGE_KEY.TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    console.error(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
service.interceptors.response.use(
  function (response) {
    console.log(
      `%cresponse[${response.config.method}]${response.config.url}`,
      "color: #008000;",
      response
    );
    const res = response.data;

    if (res.code === 401) {
      store.dispatch("setToken", "");
      errorMsgAlert(i18n.t("$.messageLogin"), () => {
        router.replace({ name: "login", params: { block: true } });
      });
    } else if (/^[45]\d{2,2}$/g.test(res.code)) {
      errorMsgAlert(i18n.t("$.messageHttpError"));
    }

    return response;
  },
  function (error) {
    console.error(error);
    // Do something with response error
    if (
      error.code === "ECONNABORTED" ||
      error.message === "Network Error" ||
      error.message.includes("timeout")
    ) {
      errorMsgAlert(i18n.t("$.messageTimeout"));
    } else {
      errorMsgAlert(i18n.t("$.messageHttpError"));
    }

    return Promise.resolve({ data: { data: null, code: null, msg: null } });
    // return Promise.reject(error);
  }
);

function errorMsgAlert(msg, callback) {
  try {
    MessageBox.close();
  } catch (error) {
    console.log(error);
  }
  Vue.prototype.$alert(msg, { callback });
}

Vue.prototype.$http = service;
export default service;
