<template>
  <div class="lang-container">
    <g-select
      popper-class="lang-select-popper"
      v-model="$root.$i18n.locale"
      @change="handleLangChange"
      size="small"
      :options="options"
    />
  </div>
</template>

<script>
import { LANG_LIST, STORAGE_KEY } from "@/utils/constants";

export default {
  data() {
    return {
      options: LANG_LIST.map(({ langCode, langName }) => ({
        label: langName,
        value: langCode,
      })),
    };
  },
  methods: {
    handleLangChange(lang) {
      localStorage.setItem(STORAGE_KEY.LANG, lang);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-container {
  ::v-deep .el-select,
  ::v-deep .el-select .el-input,
  ::v-deep .el-select .el-input .el-input__inner,
  ::v-deep .el-select .el-input.is-focus .el-input__inner,
  ::v-deep .el-select .el-input .el-select__caret {
    background-color: transparent;
    color: $tx-color;
    border-color: #fff;
  }
  ::v-deep .el-select .el-input {
    width: 120px;
  }
}
</style>

<style lang="scss">
.lang-select-popper {
  .popper__arrow {
    display: none !important;
  }
}
</style>
