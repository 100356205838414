/** ios 的 app id */
export const IOS_APP_ID = "6478998505";
/** android 的 app id */
export const ANDROID_APP_ID = "com.buzz.colex";
/** Google Play 地址 */
export const GOOGLE_PLAY_URL = `https://play.google.com/store/apps/details?id=${ANDROID_APP_ID}`;
/** App Store 地址 */
export const APP_STORE_URL = `https://apps.apple.com/app/id${IOS_APP_ID}`;
/** プライバシーポリシー*/
export const PRIVACY_POLICY_URL =
  "https://colex-pro-upload-file-bucket.s3.ap-northeast-1.amazonaws.com/appFiles/PrivacyPolicy/COLEX_PrivacyPolicy.pdf";
/** 利用規約 */
export const STATUTE_URL =
  "https://colex-pro-upload-file-bucket.s3.ap-northeast-1.amazonaws.com/appFiles/Agreement/COLEX_Agreement.pdf";
/** 特定商取引法に基づく表示 */
export const TOKUSYO_URL =
  "https://colex-pro-upload-file-bucket.s3.ap-northeast-1.amazonaws.com/appFiles/TransactionsLaw/COLEX_TransactionsLaw.pdf";

/** 全部的语言列表 */
export const LANG_LIST = [
  { langCode: "ja", langName: "日本語" },
  { langCode: "en", langName: "English" },
  // { langCode: "zh_CN", langName: "简体中文" },
  // { langCode: "zh_TW", langName: "繁体中文" },
  // { langCode: "ko", langName: "한국어" },
  // { langCode: "fr", langName: "français" },
  // { langCode: "th", langName: "ภาษาไทย" },
  // { langCode: "it", langName: "Italiano" },
  // { langCode: "pt", langName: "português" },
];

export const STORAGE_KEY = {
  TOKEN: "token",
  MAIL: "mail",
  LANG: "lang",
};

export const COOKIES_AGREE_KEY = "cookies-agree";

/** mail的正则表达式 */
export const REG_MAIL = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

/** 密码的正则表达式 */
export const REG_PW = /^(?=.*\d+)(?=.*[A-Za-z]+)[0-9a-zA-Z]{8,64}$/; // 同时包含数字和字母，最少8位

/** 验证码的正则表达式 */
export const REG_CAPTCHA = /^\d{6,6}$/;
