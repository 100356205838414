<template>
  <div class="aside-container scroll">
    <NavCom />
    <StoreCom :nav="true" />
  </div>
</template>

<script>
import NavCom from "./NavCom.vue";
import StoreCom from "./StoreCom.vue";
export default {
  components: { NavCom, StoreCom },
  methods: {
    handleClickLogo() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.aside-container {
  height: 100%;
  &.scroll {
    height: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
