import JSEncrypt from "jsencrypt";
import { PUBLIC_KEY } from "./publicKey";
import { REG_MAIL, REG_PW } from "./constants";
import i18n from "@/i18n";

/**
 * 将时间格式化
 * @param {Date} date
 * @returns 'yyyy-MM-dd hh:mm:ss'
 */
export function date2str(date) {
  if (date) {
    const yyyy = date?.getFullYear();
    const MM = ("0" + (date?.getMonth() + 1)).slice(-2);
    const dd = ("0" + date?.getDate()).slice(-2);
    const hh = ("0" + date?.getHours()).slice(-2);
    const mm = ("0" + date?.getMinutes()).slice(-2);
    const ss = ("0" + date?.getSeconds()).slice(-2);
    return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
  }
  return;
}

/**
 * 将信用卡有效期转成字符串格式
 * @returns YYYYMM
 */
export function expire2Str(year, month = 1) {
  const _month = ("0" + month).slice(-2);
  return `${year}${_month}`;
}

/**
 * 将字符串日期格式化
 * @returns yyyy/MM/dd
 */
export function dateFormat(date) {
  return date?.slice(0, 10)?.replace(/-/g, "/") || "-";
}

/**
 * 密码加密
 * @param {string} pw 未加密的密码
 * @returns
 */
export function getRsaCode(pw) {
  const encrypt = new JSEncrypt();
  const pubKey = `-----BEGIN PUBLIC KEY-----${PUBLIC_KEY}-----END PUBLIC KEY-----`;
  encrypt.setPublicKey(pubKey);
  const encrypted = encrypt.encrypt(pw);
  return encrypted || "";
}

/**
 * 密码验证
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @returns
 */
export function validatePass(rule, value, callback) {
  if (!REG_PW.test(value)) {
    return callback(this.$t("password.msgPwCheck"));
  }
  callback();
}

/**
 * 确认密码验证
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @returns
 */
export function validatePass2(rule, value, callback) {
  if (value !== this.form.password) {
    return callback(this.$t("password.msgDifferent"));
  }
  callback();
}

/**
 * mail 格式验证
 */
export function validateMail(rule, value, callback) {
  if (!REG_MAIL.test(value)) {
    return callback(this.$t("register.mailInput.msgMailMissing"));
  }
  callback();
}

/**
 * 接口返回的错误code
 */
const resErrCodeList = [
  100000001, 100000002, 100000003, 100000004, 100000005, 100000006,
];

/**
 * 错误msg中的错误code
 */
const msgErrCodeList = ["E41170099", "42G650000", "42G830000", "42G440000"];

/**
 * 绑卡接口通过返回的错误code获取i18n的key
 * @param {*} errCode
 * @param {*} msgErrCode
 * @returns
 */
export function getCardMsg(errCode, msgErrCode) {
  let msg = "";
  if (resErrCodeList.includes(errCode)) {
    if (msgErrCodeList.includes(msgErrCode)) {
      msg = i18n.t(`payment.msg${msgErrCode}`, [msgErrCode]);
    } else {
      msg = i18n.t("payment.msgOther") + (msgErrCode ? ` (${msgErrCode})` : "");
    }
  }
  return msg;
}

/** 禁用返回按钮 */
export function disableBackButton(disable = true) {
  if (disable) {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };
  } else {
    window.onpopstate = null;
  }
}
