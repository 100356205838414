<template>
  <div class="form-label-container">
    <span class="label">{{ label }}</span>
    <g-required-icon class="required" />
  </div>
</template>

<script>
export default {
  name: "g-form-label",
  props: {
    label: { type: String },
  },
};
</script>

<style lang="scss" scoped>
.form-label-container {
  .label {
    vertical-align: middle;
  }
  .required {
    margin-left: 8px;
  }
}
</style>
